import styled from 'styled-components';
import { Pill } from 'components/atoms/button';
import Link from 'components/atoms/link';
import { fontXL, fontS, fontM, TOUCHABLE_STYLE } from 'components/styles';

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) =>
    `${theme.spacing.DOUBLE_BASE_SPACING} ${theme.spacing.BASE_SPACING} ${theme.spacing.BASE_SPACING}`};
`;

export const SignupWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacing.customSpacing('24px')} 0`};
  color: ${({ theme }) => theme.new.content};
  ${fontM};
`;

export const StyledPill = styled(Pill)`
  width: ${({ theme }) => theme.spacing.customSpacing('230px')};
`;

export const LoginHeader = styled.h1`
  ${fontXL};
  color: ${({ theme }) => theme.new.content};
  margin-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
`;

export const InfoMessage = styled.p`
  ${fontM};
  color: ${({ theme }) => theme.new.content};
  padding-bottom: ${({ theme }) => theme.spacing.customSpacing('8px')};
`;

export const ErrorMessage = styled.p`
  ${fontS};
  color: ${({ theme }) => theme.new.error.background};
`;

export const LoginHeaderWrapper = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacing.customSpacing('44px')} 0 ${theme.spacing.BASE_SPACING} ${
      theme.spacing.BASE_SPACING
    }`};
`;

export const ForgotPassword = styled.div<{ error: boolean }>`
  ${fontS};
  background-color: transparent;
  color: ${({ theme }) => theme.new.information.background};
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.BASE_SPACING};
  :hover {
    text-decoration: underline;
  }
  ${({ theme, error }) =>
    error ? `padding-top: ${theme.spacing.HALF_BASE_SPACING}` : ''}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  background-color: transparent;
  color: ${({ theme }) => theme.new.information.background};
  cursor: pointer;
  margin-left: ${({ theme }) => theme.spacing.customSpacing('2px')};
  ${fontM};
  :hover {
    text-decoration: underline;
  }
`;

export const StlyedAppleLink = styled(Link)`
  ${TOUCHABLE_STYLE}
  cursor: pointer;
  margin-top: ${({ theme }) => theme.spacing.BASE_SPACING};
  box-sizing: border-box;
  ${fontXL};
  border-radius: 8px;
  padding-right: ${({ theme }) => theme.spacing.customSpacing(15)};
  padding-left: ${({ theme }) => theme.spacing.customSpacing(15)};
  height: ${({ theme }) => theme.spacing.customSpacing(48)};
  background-color: black;
  width: ${({ theme }) => theme.spacing.customSpacing('230px')};
`;
